<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      // requestType: 'GET',
    };
  },
  mounted() {
    this.getConfigList('rewards_for_group_buying_dealers_outbound_delivery_detail_report');
  },
  methods: {
  },
};
</script>
